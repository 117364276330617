const callback = () => {
  return  <div>
  <div
    style={{
      margin: 20,
      // border: "1px solid red",
      marginTop: "30%",
      padding: 20,
      boxShadow: " 0px 4px 22px rgba(0, 0, 0, 0.7)",
      borderRadius: 5,
    }}
  >
    <div
      style={{
        fontWeight: 500,
        fontSize: 24,
        padding: 20,
        color: "green",
        textAlign: "center",
      }}
    >
      Congratulation !!
    </div>
    <div
      style={{
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: 16,
        textAlign: "center",
      }}
    >
      Your appointment was successfully booked. We will get back to you
      in 24 to 48hrs.
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 30,
      }}
    >
      <div
        style={{
          padding: 10,
          background: "#3366C6",
          color: "#fff",
          fontWeight: 500,
          boxShadow: "3px 3px 2px 2px #3366c645",
        }}
        onClick={() => {
          window.location.href = "dantamitra://"
        }}
      >
        Done
      </div>
    </div>
  </div>
</div>
}

export default callback
