import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Pay from "./pay"
import Success from "./Success"
import Callback from "./callback"
import { useEffect } from "react"
var fallbackToStore = function () {
  window.location.replace("market://details?id=com.getcapacitor.myapp")
}
var openApp = function () {
  window.location.replace("your_uri_scheme://")
}
var triggerAppOpen = function () {
  openApp()
  setTimeout(fallbackToStore, 250)
}
function App() {
  useEffect(() => {
    setTimeout(() => {
      // window.location.href = "dantamitra://"
    }, 5000)
  })
  return (
    <div className="App" style={{ height: "100%" }}>
  

      <Router>
        <Switch>
          <Route path="/pay">
            <Pay />
          </Route>
          <Route path="/success">
            <Success />
          </Route>
          <Route path="/calback">
            <Callback />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App
