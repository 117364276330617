import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useHistory } from "react-router-dom";
const Pay = () => {
  const history = useHistory();
  const [rzp, setRzp] = useState(null);
  const [pi, setPI] = useState(null);
  const [paymentStatus,setPaymentStatus] = useState(false);
  

  useEffect(() => {
    var paramsString = window.location.href.split("?")[1] || "";
    var params = new URLSearchParams(paramsString);
    console.log(params.get("orderId"));
    payInit();
    let pollingInterval = setInterval(async () => {
      console.log("polling");
      let orderRes = await axios({
        method: "get",
        url: "https://api.dentamitra.com/rpOrderDetails/"+params.get("orderId"),
      });

      console.log(orderRes,orderRes.data.amount,orderRes.data.amount_paid);
      if(orderRes.data.amount===orderRes.data.amount_paid)
  {    console.log("ok success payment")
      clearInterval(pollingInterval)
      setPaymentStatus(true)
      
      var config = {
        method: "post",
        url: "https://api.dentamitra.com/booking",
        headers: {
          "Content-Type": "application/json",
          Authorization:
          "XTiaOwPBQnyClj2ovOHY5Q"
            // process.env.REACT_APP_AUTHERIZATION_TOKEN
        },
        data: {
          paymentId:  params.get("orderId"),
          orderId: params.get("orderId"),
          userId: params.get("userId"),
          status: 0,
          reasons: JSON.parse(params.get("reasons")),
        },
      }
      console.log(config)
      let result = await axios(config)
      console.log(result)
      setTimeout(() => {
        try {
          window.location.replace("dantamitra://")
        } catch (e) {
        }
      }, 1000)


      history.push("/success");
    }
      else 
      console.log("Payment not done")

    }, 2000);
    setPI(pollingInterval);
  }, []);
  const payInit = () => {
    var rzp1 = new window.Razorpay(getOptions());
    setRzp(rzp1);
    rzp1.on("payment.failed", function (response) {
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
    });

    rzp1.open();
  };
  const getOptions = (orderId, name, email, contact, ammount) => {
    var paramsString = window.location.href.split("?")[1] || "";
    var params = new URLSearchParams(paramsString);

    return {
      // key: process.env.REACT_APP_KEY_ID, // Live mode key
      key: "rzp_live_R9zwtcY7GU9Bbe", // Live mode key
      amount: parseFloat(params.get("ammount")) * 100,
      currency: "INR",
      name: "Dentamitra",
      description: "Dentamitra Payment",
      image: "https://dpay1.dentamitra.com/DentaMitra_Logo_WithShadow.png",
      order_id: params.get("orderId"),
      handler: async function (response) {
        console.log(response);
      },
      prefill: {
        name: params.get("name"),
        email: params.get("email"),
        contact: params.get("contact"),
      },
      notes: {
        orderUUID: uuidv4(),

      },
      theme: {
        color: "#3399cc",
      },
    };
  };
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid red",
        height: "100%",
        position: "absolute",
        width: "100%",
      }}
    >
      
     {!paymentStatus && <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>Payment Processing Please Wait!!</div>
        
          {rzp && (
            <button
              style={{ padding: " 5px 35px", margin: 20 }}
              onClick={payInit}
            >
              Pay
            </button>
          )}
          
            <button
              style={{ padding: " 5px 35px", margin: 20 ,marginTop:0}}
              onClick={payInit}
            >
              Cancel and go back to app
            </button>
          
        
      </div>}
      {paymentStatus && <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Payment success
        </div>}
    </div>
  );
};
export default Pay;
